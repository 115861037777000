import React, { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'

const Join = () => {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_y41qiwf', 'template_9pvj0ri', form.current, '55-PhO3_PJbzs55cu')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
    

  return (
    <div className="Join" id="join-us">
        <div className="left-j">
            <hr />
            <div>
                <span>READY TO</span>
                <span className='stroke-text'>LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY</span>
                <span className='stroke-text'>WITH US?</span>
            </div>
        </div>
        <div className="right-j">

            <form ref={form} className='email-container' onSubmit={sendEmail}>
                <input type='"email' name='user_email' placeholder='Enter your Email address'/>
                <button className='btn btn-j'>Join us</button>
            </form>
        </div>
    </div>
  )
}

export default Join