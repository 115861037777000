import './App.css';
import Hero from './componets/Hero/Hero';
import Join from './componets/join/Join';
import Programs from './componets/programs/Programs';
import Reasons from './componets/reasons/Reasons';
import Testimonials from './componets/testimonials/Testimonials';
import Plans from './componets/plans/Plans';
import Footer from './componets/footer/Footer';
function App() {
  return (
    <div className="App">
          <Hero/>
          <Programs/>
          <Reasons/>
          <Plans/>
          <Testimonials/>
          <Join/>
          <Footer/>
    </div>
  );
}

export default App;
